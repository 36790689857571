

.title-main{
    text-align: var(--text-align);
    font-size: 24px;
    color: #333;
    font-weight: 600;
}
.title-main span{
    font-size: 15px;
    font-weight: 500;
    color: #636773;
}

.bg-navbar{
    background-color:#f1f5fc !important;
}

.profile-dropdown{
    background-color: #F6F6F6 !important;
    border: 0 !important;
    color: #1D3557 !important;
    width: 140px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    border-radius: 12px !important;
}

.profile-images {
    background-color: #fff;
    border: 1px solid #e8edf7;
    border-radius: 42px;
    display: block;
    height: 36px;
    object-fit: cover;
    width: 36px;
    position: absolute;
    left: -33px;
}

.language-dropdown{
    background-color: #F6F6F6;
    border: 0;
    color: #1D3557;
    /* width: 140px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
}

.dropdown-menu[data-bs-popper] {
    top: 100% !important;
    left:  auto !important;
    right: 0 !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

.notification-item-box{
    width: 360px;
}
.notification-item-border{
    border-bottom: 1px solid #d1d1d1;
}
.notification-item-border:last-child{
    border-bottom: 0;
}

.notification-top-btn{
    color: #333!important;
    background: #fff !important;
    border: 1px solid #CFCFCF!important;
    font-size: 16px !important;
    /* padding: 8px 20px !important; */
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }
  
  .notification-top-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }
  
  .notification-top-btn.dropdown-toggle::after {
    display: none !important;
  }

 .notification-title {
    font-size: 18px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
}

.notification-subtitle {
    font-size: 14px;
    color: #404040;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 0rem;
}


.logo {
    width: 60px;
    padding: 10px 10px 10px 10px;
}


.navbar{
    background-color:#f1f5fc !important;

    .title-main{
        text-align: var(--text-align);
        font-size: 24px;
        color: #333;
        font-weight: 600;
    }
    .title-main span{
        font-size: 15px;
        font-weight: 500;
        color: #636773;
    }
    
    
    
.logo-bupa-nav{
    height: 62px;
}

.profile-dropdown{
    background-color: #F6F6F6;
    border: 0;
    color: #1D3557;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
}

.profile-images {
    background-color: #fff;
    border: 1px solid #e8edf7;
    border-radius: 42px;
    display: block;
    height: 36px;
    object-fit: cover;
    width: 36px;
    position: absolute;
    left: -33px;
}

.language-dropdown{
    background-color: #F6F6F6;
    border: 0;
    color: #1D3557;
    /* width: 140px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left:  auto;
    right: 0;;
    margin-top: var(--bs-dropdown-spacer);
}

.notification-icon{
    color: #A6A9C8;
    font-size: 24px;
    padding: 0;
}

.notification-dot{
    background-color: #FEB558 !important;
    border: 2px solid #fff !important;
    padding: 6px;
    transform: translate(-70%, -10%) !important;
}

.message-icon{
    color: #39A1EA;
    font-size: 24px;
    padding: 0;
}

}


.notification-item-box{
    width: 360px;
}
.notification-item-border{
    border-bottom: 1px solid #d1d1d1;
}
.notification-item-border:last-child{
    border-bottom: 0;
}

.notification-top-btn{
    color: #a7a9c9 !important;
    background: transparent !important;
    border: 0px solid #CFCFCF !important;
    font-size: 24px !important;
    padding: 2px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }
  
  .notification-top-btn:hover {
    color: #1279be !important;
    background: transparent !important;
    border: 0px solid transparent !important;
  }
  
  .notification-top-btn.dropdown-toggle::after {
    display: none !important;
  }

.read-message {
    background-color: #f1f5fc;
}

.read-message:hover {
    background-color: #f1f5fc!important;
}

.read-message .notification-title {
    font-size: 16px;
    color: #656565;
    font-weight: 500;
    margin-bottom: 0.3rem;
    display: flex;
    justify-content: space-between;
}

.read-message .notification-title a {
    color: #808080
}

.read-message .notification-subtitle {
    font-size: 14px;
    color: #787878;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 0rem;
}

.read-message .notification-icon{
    color: #A6A9C8;
    font-size: 24px;
    padding: 0;
}

.unread-message {
    background-color: #fff;
}

.unread-message .notification-title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin-bottom: 0.3rem;
}

.unread-message .notification-subtitle {
    font-size: 14px;
    color: #027aca;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 0rem;
}

.unread-message .notification-icon{
    color: #02335b;
    font-size: 24px;
    padding: 0;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #1279be!important;
}

.navbar .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: var(--bs-dropdown-spacer);
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    max-height: 490px;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}