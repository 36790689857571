.ContentConfigurationList{
  padding: 10px 20px;
}

.content-ContentConfigurationLis{
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  /* height: 87vh; */
  display: block;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  border-radius: 24px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
}

.content-ContentConfigurationLis::-webkit-scrollbar{
  display: none;
}

.content-ContentConfigurationLis .main-title {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.table-bg{
  background: #F1F1F2 0% 0% no-repeat padding-box;
  border-radius: 12px;
}

.table-bg .table>:not(caption)>*>* {
  background-color: #F1F1F2 !important;
  color: #636773;
}

.table-bg thead {
  border-bottom: 1px solid #707070;
}

.table-icon{
  height: 20px;
  width: 20px;
  float: left;
  margin-bottom:6px;
  margin-right: 5px;
}


.content-ContentConfigurationLis .bg-lightgreen {
background-color: #e6faec;
}

.content-ContentConfigurationLis .bg-lightblue {
background-color: #f0f4ff;
}

.content-ContentConfigurationLis .bg-lightred {
background-color: #ffeef0;
}

.content-ContentConfigurationLis .card-icon {
width: 64px;
height: 64px;
margin-bottom: 1rem;
}

.content-ContentConfigurationLis .card-title {
font-size: 20px; 
font-weight: 600; 
color: #636773; 
margin-bottom: 0.3rem; 
}

.content-ContentConfigurationLis .card-text {
font-size: 14px;
color: #636773;
margin-top: 0rem;
font-weight: 400;
text-align: center;
}

.pathways-table .table-dark {
--bs-table-color: #333;
--bs-table-bg: #D4E6EF;
--bs-table-border-color: transparent;
--bs-table-striped-bg: #2c3034;
--bs-table-striped-color: #fff;
--bs-table-active-bg: #373b3e;
--bs-table-active-color: #fff;
--bs-table-hover-bg: #323539;
--bs-table-hover-color: #fff;
color: #333;
}

.pathways-table td a {
text-decoration: none;
color: #007bff;
text-decoration: underline;
}

.pathways-table td img {
width: auto;
height: 20px;
}

.pathways-table .table-striped>tbody>tr:nth-of-type(odd)>* {
--bs-table-bg-type: #FBFBFB;
--bs-table-border-color: #E9E9E9;
}

.pathways-table .table-striped>tbody>tr:nth-of-type(even)>* {
--bs-table-bg-type: #fff;
--bs-table-border-color: #E9E9E9;
}

.appointments-table .table-dark {
--bs-table-color: #333;
--bs-table-bg: #C5C1C1;
--bs-table-border-color: transparent;
color: #333;
font-size: 14px;
font-weight: 300;
}

.appointments-table .text-Completed{
color: #01CD88 !important;
font-weight: 400;
background-color: #FAFAFA;
}

.appointments-table .text-Missed{
color: #CB0000;
font-weight: 400;
background-color: #FAFAFA;
}
.appointments-table .text-Scheduled{
color: #4D4D4D;
font-weight: 400;
background-color: #FAFAFA;
}

.appointments-table tr {
font-size: 14px;
border-color: #fff;
}
.appointments-table .bg-appointments1{
--bs-table-border-color: #F5EAF1 !important;
background-color: #F5EAF1;
color: #343434;
}
.appointments-table .bg-appointments2{
--bs-table-border-color: #ECFBFE!important;
background-color: #ECFBFE;
color: #343434;
}

.sticky-top-bar{
position: sticky !important;
top: 56px;
background-color: #f1f5fc;
z-index: 2;
/* padding-bottom: 10px; */
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
line-height: 19px !important;
max-height: 50px !important;
display: flex !important;
justify-content: space-between !important;
align-items: center !important;
overflow: auto;
border: 1px solid #DCDCDC !important;
padding: 7px 14px !important;
border-radius: 12px !important;
width: 100% !important;
background-color: #fff !important;

}

.tag-item .search {
border: none;
border-bottom: 0px solid #ccc !important;
outline: none;
}

.react-dropdown-tree-select .dropdown {
position: relative;
display: block !important;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  z-index: 1;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, .05);
  box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
  border: 1px solid #DCDCDC !important;
  padding: 2px 14px !important;
  border-radius: 12px !important;
  width: 100%;
}

.toggle {
white-space: pre;
margin-right:10px !important;
cursor: pointer;
font-style: normal !important;
font-size: 24px;
}

.node {
list-style: none;
white-space: nowrap;
padding: 4px;
display: flex !important;
align-items: center !important;
}

.node>label {
cursor: pointer;
margin-left: 2px;
display: flex !important;
align-items: center !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
cursor: pointer;
text-decoration: none !important;
}

.pathways-box{
background-color: #f0f4ff;
font-size: 20px;
color: #404040;
font-weight: 600;
padding: 6px 10px;
border-radius: 10px;
}

.body-tree-view-configuration {
margin: auto;
}

.body-tree-view-configuration > details {
width: 100%;
margin-bottom: 10px;
}
.body-tree-view-configuration > details summary {
color: #333;
padding: 8px 16px;
background-color: #F1F8FF;
border: 1px solid #F1F8FF;
border-radius: 3px;
cursor: pointer;
font-weight: 500;
}

.body-tree-view-configuration > details > details summary {
padding-left: 24px; /* Adjust indent for subchild summaries */
}

.body-tree-view-configuration > details:not([open]) summary::before {
content: " + "; /* Plus sign for closed details */
border: 1px solid #333;
padding: 0px 3px;
border-radius: 4px;
font-size:16px;
height: 16px;
width: 16px;
line-height: 12px;
display: inline-block;
margin-right: 7px;
}

.body-tree-view-configuration > details > details:not([open]) summary::before {
content: " + "; /* Plus sign for closed subchildren */
border: 1px solid #333;
padding: 0px 3px;
border-radius: 4px;
font-size:16px;
height: 16px;
width: 16px;
line-height: 12px;
display: inline-block;
margin-right: 7px;
}

.body-tree-view-configuration > details[open] summary::before {
content: " - "; /* Minus sign for open details */
border: 1px solid #333;
padding: 0px 3px;
border-radius: 4px;
font-size:16px;
height: 16px;
width: 16px;
line-height: 12px;
display: inline-block;
margin-right: 7px;
}

.body-tree-view-configuration > details > details[open] summary::before {
content: " - "; /* Minus sign for open subchildren */
border: 1px solid #333;
padding: 0px 3px;
border-radius: 4px;
font-size:16px;
height: 16px;
width: 16px;
line-height: 12px;
display: inline-block;
margin-right: 7px;
}



summary::marker {
display: none; /* or display: revert; */
font-size: 0px;
}

.body-tree-view-configuration > details > details {
margin: 5px 0;
margin-left: 35px;
position: relative;
}
.body-tree-view-configuration > details > details::after {
content: "";
position: absolute;
top: 0px;
left: -10px;
width: 1px;
height: calc(36px + 10px);
background-color: #c7d3df;
}
.body-tree-view-configuration > details > details:last-child::after {
height: calc(36px / 2 + 5px);
}
.body-tree-view-configuration > details > details::before {
content: "";
position: absolute;
top: calc(44px / 2);
left: -10px;
width: 16px;
height: 1px;
background-color: #c7d3df;
}
.body-tree-view-configuration > details > details[open]:not(:last-child)::after {
height: calc(100% + 5px);
}
.body-tree-view-configuration > details > details summary {
color: #333;
padding: 8px;
background-color: #fff;
border: 1px solid #fff;
border-radius: 3px;
cursor: pointer;
display: flex;
align-items: center;
}

/* .body-tree-view-configuration > details > details summary::-webkit-details-marker {
margin-right: 14px;
} */
.body-tree-view-configuration > details > details > details {
margin: 5px 0;
margin-left: 15px;
position: relative;
}
.body-tree-view-configuration > details > details > details::after {
content: "";
position: absolute;
top: -5px;
left: -10px;
width: 1px;
height: calc(28px + 10px);
background-color: #c7d3df;
}
.body-tree-view-configuration > details > details > details:last-child::after {
height: calc(28px / 2 + 5px);
}
.body-tree-view-configuration > details > details > details::before {
content: "";
position: absolute;
top: calc(28px / 2);
left: -10px;
width: 8px;
height: 1px;
background-color: #c7d3df;
}
.body-tree-view-configuration > details > details > details[open]:not(:last-child)::after {
height: calc(100% + 5px);
}
.body-tree-view-configuration > details > details > details summary {
margin: 5px 0;
padding: 5px 10px;
background-color: #dfe7ec;
border: 1px solid #c7d3df;
border-radius: 3px;
color: #545f69;
position: relative;
}
.body-tree-view-configuration > details > details > details summary span {
color: #041320;
}

.body-tree-view-configuration ul {
list-style-type: none;
margin: 0;
padding: 0;
}

.body-tree-view-configuration li {
margin: 5px 0;
padding: 5px 10px;
background-color: #F1F8FF;
border: 1px solid #F1F8FF;
border-radius: 3px;
color: #545f69;
position: relative;
margin-left: 26px;
position: relative;
}

.body-tree-view-configuration li:nth-child(odd) {
background-color: #F1F8FF;
border: 1px solid #F1F8FF;
}

.body-tree-view-configuration li:nth-child(even) {
background-color: #fff;
border: 1px solid #fff;
}

.body-tree-view-configuration li span {
color: #041320;
}
.body-tree-view-configuration li::after {
content: "";
position: absolute;
top: -5px;
left: -10px;
width: 1px;
height: calc(28px + 10px);
background-color: #c7d3df;
}
.body-tree-view-configuration li:last-child::after {
height: calc(28px / 2 + 5px);
}
.body-tree-view-configuration li::before {
content: "";
position: absolute;
top: calc(28px / 2);
left: -10px;
width: 8px;
height: 1px;
background-color: #c7d3df;
}
.body-tree-view-configuration li[open]:not(:last-child)::after {
height: calc(100% + 5px);
}

/* 
.pathways-table .table-dark {
--bs-table-color: #333;
--bs-table-bg: #D4E6EF;
--bs-table-border-color: transparent;
--bs-table-striped-bg: #2c3034;
--bs-table-striped-color: #fff;
--bs-table-active-bg: #373b3e;
--bs-table-active-color: #fff;
--bs-table-hover-bg: #323539;
--bs-table-hover-color: #fff;
color: #333;
}

.pathways-new-table td a {
text-decoration: none;
}

.pathways-new-tabletd img {
width: auto;
height: 20px;
}

.pathways-new-table .table-striped>tbody>tr:nth-of-type(odd)>* {
--bs-table-bg-type: #F1F8FF;
--bs-table-border-color: #F1F8FF;
border: 0;
}

.pathways-new-table .table-striped>tbody>tr:nth-of-type(even)>* {
--bs-table-bg-type: #fff;
--bs-table-border-color: #fff;
border: 0;
}

.pathways-new-table .main-pathways-title {
font-size: 18px;
color: #656565;
font-weight: 600;
margin-bottom: 0px;
}

.pathways-new-table td span {
padding-right: 16px;
color: #636773;
font-size: 14px;
} */

#ConfigurationListModal .modal-header {
display: flex;
flex-shrink: 0;
flex-direction: row !important;
}

#ConfigurationListModal .modal-bg {
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 10px 14px;
}

#ConfigurationListModal .input-field-ui-select{
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}



#ConfigurationListSMSModal .modal-header {
display: flex;
flex-shrink: 0;
flex-direction: row !important;
}

#ConfigurationListSMSModal .modal-bg {
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 10px 14px;
}

#ConfigurationListSMSModal .input-field-ui-select{
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.configurations-links-a {
font-size: 18px !important;
color: #03335b;
font-weight: 500;
padding-bottom: calc(.375rem + var(--bs-border-width));
margin-bottom: 0;
font-size: inherit;
line-height: 1.5;
display: flex;
justify-content: space-between;
}

.configurations-links-a:hover {
color: #1279be;
}

.no-arrows {
  -moz-appearance: textfield; /* Firefox */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Chrome, Safari, Edge, Opera */
}
.variable-mapping-table{

  border: 1px solid rgb(163, 163, 163);
  padding: 5px 13px;
}

.remove-template-btn{
  color: #878787;
  cursor: pointer;
  font-size: 15px;
}