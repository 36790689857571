@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #F8FBFF !important;
  /* padding: 10px; */
  /* font-family: var(--font-family); */
  overflow-y: auto !important;
  font-family: "IBM Plex Sans", sans-serif;

}

:root {
  --body-bg: #fff;
  --text-color: #000;
  --button-bg: #fff;
  --button-text: #000;
  --button-border: #000;
  --text-align: left;
  --font-family: "IBM Plex Sans", sans-serif;
}

[data-theme='arabic'] {
  --body-bg: #000;
  --text-color: #fff;
  --button-bg: #000;
  --button-text: #fff;
  --button-border: #fff;
  --text-align: right;
  --font-family: "IBM Plex Sans Arabic", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* height */
::-webkit-scrollbar {
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #eee;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03335b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03335b;
}

a {
  color: #03335b;
}

:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.sticky-top-bar {
  background-color: #f1f5fc;
  position: sticky !important;
  top: 55px !important;
  z-index: 2;
}

.en-ar-text-align {
  text-align: var(--text-align);
}

#root {
  display: flex;
}

.content {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}


.div-center-ui {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

.table-responsive {
  border-radius: 14px;
}

.add-new-program-btn {

  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.add-new-program-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.input-field-ui {
  border: 1px solid #DCDCDC !important;
  padding: 10px 14px;
  border-radius: 12px !important;
  width: 100% !important;
}

.input-field-ui:focus {
  outline: none !important;
  border: 1px solid #03335b !important;
}

.input-field-ui::placeholder {
  color: #818181 !important;
}

.input-field-ui:focus::placeholder {
  color: transparent !important;
}

.react-datepicker__input-container input {
  padding: 10px 14px !important;
}

.program-save-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 50px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.program-save-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}


.modal-backdrop {
  z-index: 1040 !important;
  background-color: #9B9B9B !important;
}

.modal-dialog {
  z-index: 1050 !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-direction: column-reverse;
  flex-wrap: wrap;
}

.modal-header {
  border: 0;
}

.modal-title {
  font-size: 26px;
  color: #404040;
  font-weight: 600;
}

.modal-subtitle {
  font-size: 18px;
  color: #404040;
  font-weight: 400;
}

.modal-content {
  border-radius: 24px !important;
}

.modal-header {
  padding-bottom: 0;
}

.uplaod-file-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.uplaod-file-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

label.col-form-label {
  font-weight: 500;
}

.clear-btn {

  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
  font-size: 16px !important;
  padding: 12px 50px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.clear-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}



.search-btn {

  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.search-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.clear-search-btn {

  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.clear-search-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.input-icon-seacrh {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 24px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #ffeba7;
  transition: all 200ms linear;
}

.react-tabs__tab--selected {
  background: #03335b !important;
  border-color: #fff !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.react-tabs__tab-list {
  border-bottom: 0px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  background: #E3E3E3 !important;
  border-color: #E3E3E3 !important;
  color: #737373 !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 34px !important;
  margin: 0 2px 0px 0 !important;
  text-transform: uppercase !important;
  ;
  cursor: pointer;
  transition: all 0.3s ease;
}

.react-tabs__tab:hover {
  background: #03335b !important;
  border-color: #fff !important;
  color: #fff !important;
}

.react-tabs__tab--selected {
  background: #03335b !important;
  border-color: #fff !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.react-tabs__tab-list {
  border-bottom: 0px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab-panel {
  display: none;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab-panel--selected .content-configuration {
  padding: 10px 20px;
}

.react-tabs__tab-list {
  border-bottom: 0px solid #aaa !important;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px !important;
  padding: 0;
}

.react-tabs__tab:first-child {
  border-radius: 14px 0 0 0px !important;
}

.react-tabs__tab:last-child {
  border-radius: 0 14px 0px 0 !important;
}

.react-tabs__tab-panel {
  display: none;
  padding: 10px;
  border-radius: 0px 14px 14px 14px !important;
  background-color: #F9F9F9;
}

.ql-editor {
  height: 130px;
}

.call-now-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 12px !important;
  padding: 2px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.call-now-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.call-now-disable-btn {
  color: #fff !important;
  background: #E2E2E2 !important;
  border: 1px solid #E2E2E2 !important;
  font-size: 12px !important;
  padding: 2px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}


.coach-call-btn {
  color: #fff !important;
  background: transparent !important;
  border: 1px solid transparent !important;
  font-size: 32px !important;
  padding: 4px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.coach-call-btn:hover {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
}


.coach-call-close-btn {
  color: #fff !important;
  background: #C10013 !important;
  border: 1px solid #C10013 !important;
  font-size: 32px !important;
  padding: 7px 10px !important;
  border-radius: 40px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  transform: rotate(135deg);
}

.coach-call-close-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
}


.coach-call-exit-btn {
  color: #fff !important;
  background: #C10013 !important;
  border: 1px solid #C10013 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 40px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  height: 36px;
}

.coach-call-exit-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
}

.coach-calling-btn {
  color: #fff !important;
  background: #32C100 !important;
  border: 1px solid #32C100 !important;
  font-size: 16px !important;
  padding: 9px 10px !important;
  border-radius: 40px !important;
  line-height: 1.1 !important;
  font-weight: 500 !important;
}

.coach-calling-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
}


.coach-calling-btn i {
  font-size: 32px !important;
}


.coach-call-enroll-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.coach-call-enroll-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.coach-call-enroll-btn.active {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.transfer-call-btn {
  color: #fff !important;
  background: #70DA40 !important;
  border: 1px solid #70DA40 !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.transfer-call-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.bookAppointment-btn {
  color: #555555 !important;
  background: transparent !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.bookAppointment-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.slot-btn {
  color: #BFBFBF !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  padding: 8px 14px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.slot-btn:hover {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
}

.slot-active-btn {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
  font-size: 16px !important;
  padding: 8px 14px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.slot-active-btn:hover {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
}

.devision-add-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 8px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.devision-add-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.program-add-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 110px;
}

.program-add-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.services-add-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.services-add-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.whatsApp-message-btn {
  color: #333 !important;
  background: #C1F3BB !important;
  border: 1px solid #C1F3BB !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.whatsApp-message-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.Email-message-btn {
  color: #333 !important;
  background: #BBEBF3 !important;
  border: 1px solid #BBEBF3 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.Email-message-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.sms-message-btn {
  color: #333 !important;
  background: #F4E8F5 !important;
  border: 1px solid #F4E8F5 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.sms-message-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.Notification-message-btn {
  color: #333 !important;
  background: #F5F4E8 !important;
  border: 1px solid #F5F4E8 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.Notification-message-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.back-btn {
  color: #656565 !important;
  background: #fff !important;
  border: 1px solid #bbc3ca !important;
  font-size: 14px !important;
  padding: 8px 10px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.back-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.btns-downloads {
  color: #656565 !important;
  background: transparent;
  border: 1px solid #bbc3ca !important;
  font-size: 16px !important;
  padding: 4px 6px !important;
  border-radius: 6px !important;
  line-height: 1 !important;
  font-weight: 600 !important;
  width: auto;
}

.btns-downloads:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.content-main-title {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.daterangepicker.ltr.show-ranges.opensright.show-calendar {
  right: 20px !important;
}


.toggle-container-CareNavigator {
  position: relative;
  width: 60px;
  height: 34px;
}

.toggle-container-CareNavigator input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-CareNavigator {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider-CareNavigator:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider-CareNavigator {
  background-color: #2196F3;
}

input:checked+.slider-CareNavigator:before {
  transform: translateX(26px);
}

.round-CareNavigator {
  border-radius: 34px;
}

.round-CareNavigator:before {
  border-radius: 50%;
}

.checked {
  background-color: #2196F3;
}

.gdBOih,
.dBiUCQ {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.call-transfer-btn {
  color: #fff !important;
  background: #56b829 !important;
  border: 1px solid #56b829 !important;
  font-size: 12px !important;
  padding: 2px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}

.call-transfer-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.call-transfer-exit-btn {
  color: #fff !important;
  background: #ff4155 !important;
  border: 1px solid #ff4155 !important;
  font-size: 12px !important;
  padding: 2px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}

.call-transfer-exit-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.offcanvas-backdrop.show {
  background-color: #9B9B9B !important;
}

.dashboard-graph-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  -webkit-column-count: 3;
  /* For older WebKit browsers */
  -webkit-column-gap: 1rem;
  /* For older WebKit browsers */
  -moz-column-count: 3;
  /* For older Firefox browsers */
  -moz-column-gap: 1rem;
  /* For older Firefox browsers */
}

.dashboard-graph-box {
  background-color: #fff;
  color: #000;
  padding: 16px 16px 0px 16px;
  border-radius: 14px;
  height: 100%;
  display: block;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.dashboard-graph-box {
  display: inline-block;
}

.dashboard-graph-title {
  font-size: 18px;
  font-weight: 500;
  color: #636773;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-graph-box-content {
  font-size: 80px;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.dashboard-graph-title i {
  cursor: pointer;
  vertical-align: middle !important
}

.dashboard-graph-number {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.dashboard-graph-subtitle {
  font-size: 15px;
  font-weight: 400;
  color: #636773;
  margin-bottom: 0rem;
}

.dashboard-graph-subtitle span {
  color: #333 !important;
  font-weight: 600;
}

.tooltip-inner {
  background-color: #1279be;
  color: #fff;
}

.clock-icon1 {
  height: 17px;
  position: absolute;
  left: 30px;
  z-index: 0;
  top: 10px;
}

.clock-icon {
  height: 17px;
  position: absolute;
  left: 20px;
  z-index: 0;
  top: 10px;
}

.bookAppointment-btn {
  color: #555555 !important;
  background: transparent !important;
  border: 1px solid #CFCFCF !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.bookAppointment-btn:hover,
.bookAppointment-btn.active {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

#slotBookModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

.time_slot_book {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  margin: 2px 5px;
  padding: 6px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.time_slot_book.active {
  color: #fff;
  background-color: #1279be;
  border: 1px solid #1279be;
}

.time_slot-d-flex {
  display: flex !important;
  flex-wrap: wrap !important;
  /* justify-content: center; */
  width: 100%;
}

.call-status-message {
  font-size: 11px;
  color: #fff;
  margin-top: .5rem;
}

.save-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.save-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}



.messageDiv {
  background-color: transparent !important;
  color: #017221 !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 5px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  border-radius: 10px !important;
  border: 1px solid transparent !important;
}

.errorDiv {
  background-color: transparent;
  color: #d60023 !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 5px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  border-radius: 10px !important;
  border: 1px solid transparent !important;
}


.coach-call-reject-btn {
  color: #fff !important;
  background: #C10013 !important;
  border: 1px solid #C10013 !important;
  font-size: 20px !important;
  padding: 8px 30px !important;
  border-radius: 40px !important;
  line-height: 1.1 !important;
  font-weight: 600 !important;
}

.coach-call-reject-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.coach-call-accept-btn {
  color: #fff !important;
  background: #32C100 !important;
  border: 1px solid #32C100 !important;
  font-size: 20px !important;
  padding: 8px 30px !important;
  border-radius: 40px !important;
  line-height: 1.1 !important;
  font-weight: 600 !important;
}

.coach-call-accept-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.health-locker-btn {
  color: #333 !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  padding: 6px 10px !important;
  border-radius: 8px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.health-locker-btn:hover {
  color: #fff !important;
  background: #578FFF !important;
  border: 1px solid #578FFF !important;
}

.service-action {
  border: 1px solid #BBBBBB !important;
  border-radius: 10px !important;
  color: #343434 !important;
  font-size: 16px !important;
  background-color: #fdfdfd !important;
  padding: 3px 12px !important;
  width: 100% !important;
}

.disabled a {
  background-color: #e3e3e3;
  cursor: text !important;
  color: #9f9f9f;
}

.disabled a:hover {
  background-color: #e3e3e3;
  cursor: text !important;
  color: #9f9f9f;
}

.control-input-ui {
  border: 1px solid #BBBBBB !important;
  border-radius: 14px !important;
  color: #343434 !important;
  font-size: 18px !important;
  background-color: #fdfdfd !important;
  padding: 8px 12px !important;
}

.control-input-ui:disabled {
  background-color: #F5F5F5 !important;
  border: 1px solid #e0e0e0 !important;
  opacity: 1;
}


.select-input-ui {
  border: 1px solid #BBBBBB !important;
  border-radius: 14px !important;
  color: #343434 !important;
  font-size: 18px !important;
  background-color: #fdfdfd !important;
  padding: 8px 12px !important;
  width: 100% !important;
}

.select-input-ui:disabled {
  background-color: #F5F5F5 !important;
  border: 1px solid #e0e0e0 !important;
  opacity: 1;
}

.select-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-check-input-ui {
  background-color: #fff !important;
  border: 1px solid #707070 !important;
  border-radius: 2px !important;
  padding: 10px !important;
  margin-right: 10px;
}

.form-check-input-ui:checked {
  background-color: #1279be !important;
  border: 1px solid #1279be !important;
  border-radius: 2px !important;
  padding: 10px !important;
}

.form-check-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}


.coach-call-close-btn {
  color: #fff !important;
  background: #C10013 !important;
  border: 1px solid #C10013 !important;
  font-size: 32px !important;
  padding: 7px 10px !important;
  border-radius: 40px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  transform: rotate(135deg);
}

.coach-call-close-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
}

/* 
.coach-call-enroll-btn{
    
  color: #fff!important;
  background: #1279BE !important;
  border: 1px solid #1279BE !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.coach-call-enroll-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
} */

.transfer-call-btn {

  color: #fff !important;
  background: #70DA40 !important;
  border: 1px solid #70DA40 !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.transfer-call-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.slot-btn {
  color: #BFBFBF !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  padding: 8px 14px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.slot-btn:hover {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
}

.slot-active-btn {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
  font-size: 16px !important;
  padding: 8px 14px !important;
  border-radius: 6px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.slot-active-btn:hover {
  color: #fff !important;
  background: #003CFF !important;
  border: 1px solid #003CFF !important;
}

.box-draggable {
  background-color: #4a4a4a;
  border: 0;
  border-radius: 24px;
  padding: 10px;
  width: auto;
  /* height: 150px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  position: fixed;
  top: 40%;
  left: 40%;
  z-index: 9999;
  color: #000;
}

.coach-call-close-btn {
  color: #fff !important;
  background: #C10013 !important;
  border: 1px solid #C10013 !important;
  font-size: 32px !important;
  padding: 7px 10px !important;
  border-radius: 40px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  transform: rotate(135deg);
}

.ql-editor {
  height: 130px;
}

.emty-column {
  background-image: url(https://storage.googleapis.com/ksabupatest/2024/09/24/th7uc/43v0fjxsma.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emty-line {
  background-image: url(https://storage.googleapis.com/ksabupatest/2024/09/24/d8to7/m07jt1m0va.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emty-pie {
  background-image: url(https://storage.googleapis.com/ksabupatest/2024/09/24/8jy4j/6174j4so3a.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emty-title-graph {
  font-size: 16px;
  font-weight: 500;
  color: #636773;
  text-align: center;
}

/* 
#reportrange {
  gap: 6px;
} */

.rmsc .gray {
  color: #343434 !important;
}

.css-tj5bde-Svg {
  color: #343434 !important;
}


#showListDataTable .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

#showListDataTable .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#commonModulePop .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

#commonModulePop .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}


#callHistoryDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

#callHistoryDetails .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.bg-grey-new {
  border-radius: 14px;
  border: none;
  background-color: #f0f4ff;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}

.treeDropdownList .dropdown-trigger input::placeholder {
  color: black !important;
  /* Your desired placeholder color */
}

.treeDropdownList .search input::placeholder {
  color: black !important;
  /* Additional targeting for the search input */
}

#callStatusModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

#callStatusModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#hbsh .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#hbsh .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.program-add-new-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.program-add-new-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.remove-btn {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
  font-size: 14px !important;
  padding: 10px 16px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.remove-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


#altMobileModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#altMobileModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#viewAssessment .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#viewAssessment .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.bx-info-circle {
  cursor: pointer;
  vertical-align: middle !important;
}

#viewHRA .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#viewHRA .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#AddNewRecordDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#AddNewRecordDetails .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#EditRecordDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#EditRecordDetails .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#notesModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#notesModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}


#monthSelectedServices .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#monthSelectedServices .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#addNewServiceModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#addNewServiceModal h4 {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

#editServiceModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#editServiceModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#deleteServiceModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#deleteServiceModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#viewServiceModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#viewServiceModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#userDeleteServiceModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#userDeleteServiceModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
  vertical-align: middle;
}

.editable-mobile-no::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner for Firefox */
.editable-mobile-no {
  -moz-appearance: textfield;
}



#pathwaysProgramsModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#pathwaysProgramsModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.program-submit-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 16px !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.program-submit-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

#conditionsupdateModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#conditionsupdateModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#newProgramModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#newProgramModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}


#showAddProgramModalLabel .main-title {
  font-size: 20px !important;
  color: #656565 !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

#showAddProgramModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#showAddConditionModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.2;
}

#showAddConditionModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#showEditProgramModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.2;
}

#showEditProgramModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.draft-status {
  font-size: 11px;
  border: 1px solid #e2251b;
  padding: 1px 8px;
  border-radius: 12px;
  color: #e2251b !important;
  margin-left: 10px;
  cursor: pointer;
  cursor: default !important;
  font-weight: 500;
}

#showAddPathwayModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.2;
}

#showAddPathwayModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#showEditConditionModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.2;
}

#showEditConditionModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#showEditPathwayModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.2;
}

#showEditPathwayModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.dropdown-content {
  z-index: 999999 !important;
  margin-bottom: 25px !important;
}

.modal-fullscreen {
  width: 95vw;
  height: 85vh;
  max-width: 95vw;
  max-height: 90vh;
  margin: auto;
  margin-top: 2%;
}

.BackendDrivenDataTable table span {
  background-color: transparent;
}

.BackendDrivenDataTable table td {
  white-space: normal;
  vertical-align: middle;
  font-size: 14px;
}

.BackendDrivenDataTable .table>:not(caption)>*>* {
  background-color: transparent !important;
}

.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker__input-container {
  width: auto;
}

.react-datepicker {
  font-family: inherit;
  border-radius: 0.375rem;
  border-color: #e5e7eb;
}

.react-datepicker__header {
  background-color: #f9fafb;
  border-bottom-color: #e5e7eb;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #3b82f6 !important;
  color: white !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: rgba(59, 130, 246, 0.5);
}

.react-datepicker__day:hover {
  background-color: #e5e7eb;
}

#showAddProgramModalLabel .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#showAddProgramModalLabel .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}


#observationModule .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#observationModule .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#reEnrollment .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

#reEnrollment .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}


#showAddProgramModalLabelNew .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#showAddProgramModalLabelNew .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

.w-10 {
  width: 10% !important;
}


#metricsAdd .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#metricsAdd .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#metricsInsights .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#metricsInsights .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}


#stopPathway .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#stopPathway .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#restartPathway .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#restartPathway .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#HealthMetricsPopUp .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#HealthMetricsPopUp .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}
#HealthMetricsDetailsPopUp .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#HealthMetricsDetailsPopUp .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#languageModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#languageModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#addNewDispositionModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#addNewDispositionModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

#addNewContractPriorityModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#addNewContractPriorityModal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #656565;
}

@media print {
  body * {
    visibility: hidden;
    /* Hide everything by default */
  }

  #print-area,
  #print-area * {
    visibility: visible !important;
    /* Make the print area visible */
  }

  #print-area {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .no-print {
    display: none;
    /* Explicitly hide elements with no-print class */
  }
}
@media print {
  body * {
    visibility: hidden;
  }

  #printArea, #printArea * {
    visibility: visible;
  }

  #printArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  /* Define page size and scaling for printing */
  @page {
    size: A4; /* A4 is a common paper size, you can adjust as needed */
    margin: 10mm; /* Adjust margins to avoid clipping */
  }

  /* Optionally scale the content to fit */
  #printArea {
    width: 100%;
    height: 100%;
    transform: scale(0.7); /* Scale content to fit better */
    transform-origin: top left; /* Ensure scaling starts from the top left corner */
  }

  /* Prevent scaling from clipping text and content */
  #printArea h2, #printArea p {
    margin: 0;
    padding: 0;
  }
  .no-print {
    display: none;
    /* Explicitly hide elements with no-print class */
  }
}
.data-not-bg {
  background-color: #fff;
  /* padding: 2px; */
  border-radius: 14px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.data-not-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0rem;
}

.data-not-box {
  background: #c2e7ff;
  border-radius: 12px;
  padding: 12px;
}

.data-img-no {
  height: 26vh;
}

.icons-hover{
  color: #03335b;
}

.icons-hover:hover{
  color: #1279be;
}

.iejuCC div:first-child, .jURlRn div:first-child {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: ellipsis !important;
}

.btn-edu {
  border: 1px solid #BBBBBB !important;
  border-radius: 10px !important;
  color: #343434 !important;
  font-size: 12px !important;
  background-color: #fdfdfd !important;
  padding: 4px 10px !important;
  width: 100% !important;
  text-align: center;
}

/* .btn-edu:hover {
  color: #fff !important;
  background: #578FFF !important;
  border: 1px solid #578FFF !important;
} */


.btn-edu-1 {
  border: 1px solid #03335b !important;
  border-radius: 10px !important;
  color: #fff !important;
  font-size: 14px !important;
  background-color: #03335b!important;
  padding: 2px 10px !important;
  width: 100% !important;
  font-weight: 600;
}

.btn-edu-1:hover {
  color: #fff !important;
  background: #578FFF !important;
  border: 1px solid #578FFF !important;
}


.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff !important;
}


/* Priority Status */

.priority-default{
  background-color:#fff;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
}

.priority-high{
  background-color:#0079c8;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
}

.priority-medium{
  background-color:#ec8938;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
}

.priority-low{
  background-color:#fbc525;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
}

.priority-normal{
  background-color:#05b8b5;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  display: inline-block;
}

.priority-border-default{
  border: 2px solid #bbb;
}

.priority-border-high{
  border: 2px solid #0079c8;
}

.priority-border-medium{
  border: 2px solid #ec8938;
}

.priority-border-low{
  border: 2px solid #fbc525;
}

.priority-border-normal{
  border: 2px solid #05b8b5;
}

.priority-bg-default{
  background-color:#fff !important;
}

.priority-bg-high{
  background-color:rgb(0 122 200 / 15%) !important;
}

.priority-bg-medium{
  background-color:rgb(236 137 56 / 15%) !important;
}

.priority-bg-low{
  background-color:rgb(251 197 37 / 15%) !important;
}

.priority-bg-normal{
  background-color:rgb(10 184 181 / 15%) !important;
}

.priority-div{
  position: absolute;
  padding: 3px;
  height: 14px;
  width: 14px;
  border-radius: 20px;
  margin: 4px;
  right: 0;
}

.priority-div-profile{
  position: absolute;
  margin: 6px;
  right: 0;
}

.priority-position{
  position:relative;
}


.cal-position{
  display: flex;
  width: 100%;
}


/* calender folloUp*/
.followup-new{
  color: #0ba537 !important;
}

.followup-current{
  color: #d60023 !important;
}