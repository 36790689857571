.LoginPage {
    background-color: #1279BE;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    align-items: center;
    background-attachment: fixed;
    background-image: url(https://storage.googleapis.com/ksabupatest/2024/09/24/r379w/v189jhhuhh.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
}

.language-setting{
    position: absolute;
    top: 10px;
    right: 10px;
}

.powered-by{
    width: 100%;
    max-width: 1000px;
    text-align: right;
}

.LoginPage .card-login{
    width: 100%;
    max-width: 1000px;
    background-color: #fff;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 1px 0 rgba(17, 17, 26, .05), 0 0 8px rgba(17, 17, 26, .1);
    padding: 20px;
}

.LoginPage .logo-ksa{
    height: 82px;
    margin-bottom: 2rem;
}

.LoginPage .login-title{
    font-size: 35px;    
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
    /* text-align: var(--text-align); */
    text-align: center;
}

.LoginPage .login-title span{
    font-weight: 400;
}

.LoginPage .login-subtitle{
    font-size: 24px;    
    color: #656565;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}

.LoginPage .img-login {
    border-radius: 34px;
    background-color: #D8F4FB;
    padding: 20px;
}

.LoginPage .control-input{
    background-color: #F8F8F8 !important;
    border: none !important;
    border-radius: 10px;
    padding: 10px 20px 10px 46px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 16px;
    text-align: var(--text-align);
}
.form-group{ 
    position: relative;
    display: block;
      margin: 0;
      padding: 0;
      text-align: var(--text-align);
}
.input-icon {
    position: absolute;
    top: 8px;
    left: 10px;
    height: 24px;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: #ffeba7;
    transition: all 200ms linear;
  }

.LoginPage .control-input:focus{
    outline: none !important;
    box-shadow: none !important;
}

.LoginPage .btn-login{
    background-color: #fff;
    border: 1px solid #707070;
    color: #656565;
    border-radius: 16px;
    padding: 8px 20px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.LoginPage .btn-login:hover{
    background-color: #1279be;
    border: 1px solid #1279be;
    color: #fff;
}

.LoginPage .btn-login-box{
    background-color: #fff;
    border: 1px solid #C7C7C7;
    color: #656565;
    border-radius: 16px;
    padding: 8px 20px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    width: 140px;
    text-align: center;
    height: 140px;
    transition: 0.3s;
}

.LoginPage .btn-login-box:hover{
    background-color: #F5F5F5;
    color: #333;
    border: 1px solid #C7C7C7;
    transition: 0.2s;
}

.LoginPage .box-ksa-icon{
    height: 62px;
}

.LoginPage .login-box-title{
    font-size: 18px;    
    color: #656565;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: center;
}